import * as React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/parts/hero"
import Section from "../components/parts/section"
import Stats from "../components/parts/stats"
import HalfImageHalfContent from "../components/parts/halfimagehalfcontent"
import CTA from "../components/parts/cta"
import ContactForm from "../components/parts/contact"

const generalPage = ({data}) => (
  <Layout>
    <SEO title={data.allPrismicPage.nodes[0].data.title.text} />
    {data.allPrismicPage.nodes.map(page => (
      <div key={page.uid}>
        {console.log(page.data.title.text)}
        {page.data.body.map( section => (
          <>
            {section.slice_type === "hero_section" &&
              <Hero key={section.id} content={section} parent={page.data.title.text} />  
            }
            {section.slice_type === "generic_section" &&
              <Section key={section.id} content={section} />  
            }
            {section.slice_type === "image_with_stats" &&
              <Stats key={section.id} content={section} />  
            }
            {section.slice_type === "1_2___1_2" &&
              <HalfImageHalfContent key={section.id} content={section} />  
            }
            {section.slice_type === "call_to_action" &&
              <CTA key={section.id} content={section} />  
            }
            {section.slice_type === "contact_form" &&
              <ContactForm key={section.id} content={section} />  
            }
          </>
        ))}
      </div>
    ))}
  </Layout>
)

export default generalPage

export const query = graphql`
  query PageQuery($uid: String)
  {
    allPrismicPage(filter:{ uid: { eq: $uid } }) {
      nodes {
        uid
        data {
          meta_title
          meta_description
          title {
            text
          }
          body {
            ... on PrismicPageBodyHeroSection {
              id
              slice_type
              primary {
                section_name
                background_color
                background_size
                background_image {
                  url
                }
                hero_title {
                  text
                }
                hero_content {
                  text
                }
              }
            }
            ... on PrismicPageBodyGenericSection {
              id
              slice_type
              slice_label
              primary {
                section_name
                section_background {
                  url
                  alt
                }
                background_size
                bg_position
                section_title {
                  text
                }
                section_content {
                  text
                }
                call_to_action
                button_text
                button_link {
                  url
                }
                icon_columns
              }
              items {
                iwt_image {
                  alt
                  url
                }
                iwt_title {
                  text
                }
                iwt_content {
                  text
                }
                iwt_link {
                  url
                }
              }
            }
            ... on PrismicPageBodyImageWithStats {
              id
              slice_type
              slice_label
              primary {
                section_name
                section_title {
                  text
                }
                content {
                  text
                }
                image {
                  alt
                  url
                }
              }
              items {
                stat_icon {
                  alt
                  url
                }
                key_fact
                fact_measurement
              }
            }
            ... on PrismicPageBody1212 {
              id
              slice_type
              slice_label
              primary {
                section_name
                image {
                  alt
                  url
                }
                image_side
                image_size
                section_title {
                  text
                }
                section_content {
                  text
                }
              }
            }
            ... on PrismicPageBodyCallToAction {
              id
              slice_type
              slice_label
              primary {
                section_name
                cta_image {
                  alt
                  url
                }
                cta_title {
                  text
                }
                cta_content {
                  text
                }
                button_text
                button_link {
                  url
                }
              }
            }
            ... on PrismicPageBodyContactForm {
              id
              slice_type
              slice_label
              primary {
                section_name
                email_addresses
              }
            }
          }
        }
      }
    }
  }
`