import React from "react"

const cta = (props) => {
    return(
    <section className="text-gray-600 body-font homeHero pt-16 pb-10">
        <div class="container px-5 py-24 mx-auto">
            <div className="flex flex-wrap w-4/5 mx-auto mb-20 flex-col items-center text-center">
                {props.content.primary.section_name!== "" &&
                    <h5 className="sm:text-base text-lg uppercase font-normal title-font mb-10 text-elite-light">{props.content.primary.section_name}</h5>
                }
                {props.content.primary.cta_title.text &&
                    <h2 className="sm:text-4xl text-4xl font-extralight title-font mb-20 text-gray-900">{props.content.primary.cta_title.text}</h2>
                }
                {props.content.primary.cta_content.text &&
                    <p className="lg:w-1/2 w-full leading-relaxed text-gray-500">{props.content.primary.cta_content.text}</p>
                }
            </div>
            {props.content.primary.button_text !== null || props.content.primary.button_text !== "" &&
                <>
                    <button className="flex mx-auto mt-16 text-white bg-elite-dark rounded-full border-0 py-2 px-8 focus:outline-none hover:bg-elite-light text-base font-light uppercase">{props.content.primary.button_text}</button>
                </>
            }
            <a href={props.content.primary.button_link.url}><button className="flex mx-auto mt-16 text-white bg-elite-dark rounded-full border-0 py-2 px-8 focus:outline-none hover:bg-elite-light text-base font-light uppercase">{ props.content.primary.button_text }</button></a>
        </div>
    </section>
    )
}
  
export default cta 