import React from "react"
import { Formik, Form, Field, ErrorMessage } from 'formik'

const ContactForm = (props) => {
    return(
    <section className="text-gray-600 body-font relative">
        <Formik
            initialValues={{
                name: '',
                phone: '',
                email: '',
                message: '',
            }}
            onSubmit={(values, actions) => {
                alert(JSON.stringify(values, null, 2));
                actions.setSubmitting(false);
            }}
            validate={values => {
                const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
                const errors = {};
                if(!values.name) {
                errors.name = 'Name Required'
                }
                if(!values.phone) {
                    errors.phone = 'Phone No. Required'
                    }
                if(!values.email || !emailRegex.test(values.email)) {
                errors.email = 'Valid Email Required'
                }
                if(!values.message) {
                errors.message = 'Message Required'
                }
                return errors;
            }}
        >
        <Form>
        <div className="container px-5 py-24 mx-auto">
            <div className="lg:w-2/3 md:w-2/3 mx-auto">
                <div className="flex flex-wrap -m-2">
                    <div className="p-2 w-1/2 pb-4">
                        <div className="relative">
                            {/* <label for="name" className="leading-7 text-sm text-gray-600">Name</label> */}
                            <Field name="name" id="name" type="text" className="w-full bg-gray-100 bg-opacity-50 rounded-full border-2 border-elite-light focus:border-elite-dark focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-elite-dark py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" placeholder="Your name" />
                            <div className="text-xs pl-5 mt-1 text-red-500"><ErrorMessage name="name" /></div>
                        </div>
                    </div>
                    <div className="p-2 w-1/2">
                        <div className="relative">
                            {/* <label for="name" className="leading-7 text-sm text-gray-600">Name</label> */}
                            <Field name="company" id="company" type="text" className="w-full bg-gray-100 bg-opacity-50 rounded-full border-2 border-elite-light focus:border-elite-dark focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-elite-dark py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" placeholder="Your company name" />
                            {/* <div className="text-xs pl-6 text-red-500"><ErrorMessage name="company" /></div> */}
                        </div>
                    </div>
                    <div className="p-2 w-1/2">
                        <div className="relative">
                            {/* <label for="name" className="leading-7 text-sm text-gray-600">Name</label> */}
                            <Field name="phone" id="phone" type="text" className="w-full bg-gray-100 bg-opacity-50 rounded-full border-2 border-elite-light focus:border-elite-dark focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-elite-dark py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" placeholder="Phone No." />
                            <div className="text-xs pl-5 mt-1 text-red-500"><ErrorMessage name="phone" /></div>
                        </div>
                    </div>
                    <div className="p-2 w-1/2">
                        <div className="relative">
                            {/* <label for="name" className="leading-7 text-sm text-gray-600">Name</label> */}
                            <Field name="email" id="email" type="email" className="w-full bg-gray-100 bg-opacity-50 rounded-full border-2 border-elite-light focus:border-elite-dark focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-elite-dark py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" placeholder="Email" />
                            <div className="text-xs pl-5 mt-1 text-red-500"><ErrorMessage name="email" /></div>
                        </div>
                    </div>
                    <div className="p-2 w-1/2">
                        <div className="relative">
                            {/* <label for="name" className="leading-7 text-sm text-gray-600">Name</label> */}
                            <select id="purpose" name="purpose" className="w-full bg-gray-100 bg-opacity-50 rounded-full border-2 border-elite-light focus:border-elite-dark focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-elite-dark py-1 px-3 leading-8 transition-colors duration-200 ease-in-out">
                                <option value="Choose an option">Choose an option</option>
                                <option value="Future tender requirements">Future tender requirements</option>
                                <option value="CLP">Current logistics packages</option>
                                <option value="PRC">Pricing</option>
                                <option value="COP">Career Opportunity</option>
                                <option value="GEQ">General enquiry</option>
                                <option value="Other">Other</option>
                            </select>
                            {/* <input type="text" id="name" name="name" className="w-full bg-gray-100 bg-opacity-50 rounded-full border-2 border-gray-300 focus:border-elite-light focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-elite-dark py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" placeholder="Your name" /> */}
                        </div>
                    </div>
                    <div className="p-2 w-1/2">
                        <div className="relative">
                            {/* <label for="name" className="leading-7 text-sm text-gray-600">Name</label> */}
                            <input type="text" id="location" name="location" className="w-full bg-gray-100 bg-opacity-50 rounded-full border-2 border-elite-light focus:border-elite-dark focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-elite-dark py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" placeholder="Location" />
                        </div>
                    </div>
                    <div className="p-2 w-full">
                        <div className="relative">
                            {/* <textarea id="message" name="message" className="w-full bg-gray-100 bg-opacity-50 rounded-3xl border-2 border-elite-light focus:border-elite-dark focus:bg-white focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out" placeholder="Your message"></textarea> */}
                            <Field name="message" id="message" component="textarea" rows="5" className="w-full bg-gray-100 bg-opacity-50 rounded-3xl border-2 border-elite-light focus:border-elite-dark focus:bg-white focus:ring-2 focus:ring-indigo-200 text-base outline-none text-elite-dark py-1 px-3 leading-8 transition-colors duration-200 ease-in-out" placeholder="Your message" />
                            <div className="text-xs pl-5 mt-1 text-red-500"><ErrorMessage name="message" /></div>
                        </div>
                    </div>
                    <div className="p-2 w-full">
                        <button className="flex mx-auto text-white bg-elite-dark border-0 py-2 px-8 focus:outline-none hover:opacity-70 rounded-full text-base">Send Message</button>
                    </div>
                    
                </div>

            </div>
        </div>
        </Form>
        </Formik>
    </section>
    )
}
  
export default ContactForm